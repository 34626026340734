






































































import { defineComponent, ref } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('min', {
    ...min,
    message: '{_field_} must be longer than or equal to 8 characters.',
});

export default defineComponent({
    name: 'RunnerTokens',
    components: { ValidationProvider },
    props: {
        tokenGeneration: {
            type: Object,
        },
        generatedToken: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const tokenToCopy = ref<any>(null);

        const copyToClipboard = () => {
            tokenToCopy.value = document.querySelector('#token');
            if (tokenToCopy.value.value) {
                tokenToCopy.value.setAttribute('type', 'text');
                tokenToCopy.value.select();
                document.execCommand('copy');
                tokenToCopy.value.setAttribute('type', 'hidden');
            }
        };

        return { emit, copyToClipboard };
    },
});
