













































































import { FormModal } from '@/app/components';
import { Asset } from '@/modules/asset/types';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { Workflow } from '@/modules/workflow-designer/types';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { AssetTypeId } from '../../asset/constants/asset-type-id.constants';

export default defineComponent({
    name: 'DerivedAssetsModal',
    components: {
        FormModal,
    },
    props: {
        derivedAssets: {
            type: Array as PropType<Asset[]>,
            required: true,
        },
        derivedWorkflows: {
            type: Array as PropType<Workflow[]>,
            required: true,
        },
        derivedRetrievalQueries: {
            type: Array as PropType<RetrievalQuery[]>,
            required: true,
        },
    },
    setup(props) {
        const derivedModels = computed(() =>
            props.derivedAssets.filter((asset: any) => asset.assetTypeId === AssetTypeId.Model),
        );

        const derivedResults = computed(() =>
            props.derivedAssets.filter((asset: any) => asset.assetTypeId === AssetTypeId.Result),
        );

        return {
            derivedModels,
            derivedResults,
        };
    },
});
