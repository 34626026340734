





























































import { ExceptionEventsAPI } from '@/app/api';
import { TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { RefreshIcon, XIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
export default defineComponent({
    name: 'ExceptionEvents',
    components: {
        RefreshIcon,
        XIcon,
        TailwindTable,
    },
    props: {
        query: {
            type: Object as PropType<{ sortBy: { field: string; asc: boolean } }>,
        },
    },
    setup(props, { root, emit }) {
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const { exec, loading } = useAxios(true);

        const columns = [
            {
                key: 'id',
                label: '',
                type: ColumnType.String,
            },
            {
                key: 'groupId',
                label: 'Group Id',
                type: ColumnType.String,
            },
            {
                key: 'topic',
                label: 'Topic',
                type: ColumnType.String,
            },
            {
                key: 'error',
                label: 'Error',
                type: ColumnType.String,
            },
            {
                key: 'key',
                label: 'Key',
                type: ColumnType.String,
            },
            {
                key: 'createdAt',
                label: 'Timestamp',
                type: ColumnType.Datetime,
                sortable: true,
                formatInUtc: true,
            },
        ];

        const exceptions = ref<any[]>([]);
        const { pagination, handleChangePage } = usePagination(root, { pageSize: 20 });

        const handleChangeSort = (sortBy: { field: string; asc: boolean }) => emit('sort', sortBy);

        const changePage = (newPage: number) => {
            handleChangePage(newPage);
            getExceptions();
        };

        const getExceptions = async () => {
            await exec(ExceptionEventsAPI.getEvents(pagination.value, props.query?.sortBy))
                .then((res: any) => {
                    exceptions.value = res?.data?.data.map((exceptionEvent: any) => {
                        return {
                            id: exceptionEvent.id,
                            groupId: exceptionEvent.groupId,
                            topic: exceptionEvent.topic,
                            error: exceptionEvent.error,
                            key: exceptionEvent.key,
                            createdAt: exceptionEvent.createdAt,
                        };
                    });
                    pagination.value.pageSize = res.data.meta.itemsPerPage;
                    pagination.value.total = res.data.meta.totalItems;
                    initAllCheckboxes(false);
                })
                .catch(() => {
                    initAllCheckboxes(false);
                    (root as any).$toastr.e('Error retrieving exception events', 'Error');
                });
        };

        const triggerEvents = () => {
            if (!exceptionIdsSelected.value || exceptionIdsSelected.value.length === 0) return;
            exec(
                ExceptionEventsAPI.trigger({
                    exceptionEventIds: exceptionIdsSelected.value,
                }),
            )
                .then(() => getExceptions())
                .catch(() => {
                    exceptions.value = [];
                    (root as any).$toastr.e('Error triggering selected exception events', 'Error');
                });
        };

        const ignoreEvents = () => {
            if (!exceptionIdsSelected.value || exceptionIdsSelected.value.length === 0) return;
            exec(
                ExceptionEventsAPI.ignore({
                    exceptionEventIds: exceptionIdsSelected.value,
                }),
            )
                .then(() => getExceptions())
                .catch(() => {
                    exceptions.value = [];
                    (root as any).$toastr.e('Error ignoring selected exception events', 'Error');
                });
        };

        const exceptionIdsSelected = computed(() =>
            Object.keys(selectedCheckboxes.value).filter((id: string) => selectedCheckboxes.value[id]),
        );

        const selectedCheckboxes = ref<Record<any, boolean>>({});
        const selectedAll = ref<boolean>(false);

        const initAllCheckboxes = (value: boolean) => {
            selectedCheckboxes.value = R.reduce(
                (acc, exception) => R.assoc(exception.id, value, acc),
                {},
                exceptions.value,
            );
        };

        const checkboxClicked = (id: any) => {
            selectedAll.value = false;
            selectedCheckboxes.value[id] = !selectedCheckboxes.value[id];
        };

        const checkboxAllClicked = () => {
            selectedAll.value = !selectedAll.value;
            initAllCheckboxes(selectedAll.value);
        };

        watch(
            () => props.query,
            (query) => {
                if (query) {
                    getExceptions();
                }
            },
            { immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            loading,
            isAdmin,
            exceptions,
            columns,
            exceptionIdsSelected,
            triggerEvents,
            ignoreEvents,
            handleChangeSort,
            changePage,
            pagination,
            selectedCheckboxes,
            selectedAll,
            checkboxClicked,
            checkboxAllClicked,
        };
    },
});
