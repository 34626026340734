


















import { useAxios } from '@/app/composable';
import { defineComponent, ref } from '@vue/composition-api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { AdminAPI } from '../../api';

export default defineComponent({
    name: 'Overview',
    components: {
        SelfBuildingSquareSpinner,
    },
    setup(props, { root }: { root: any }) {
        const { exec, loading } = useAxios(true);

        const metricLabels = [
            {
                key: 'activeOrganisations',
                label: 'Active organisations',
            },
            {
                key: 'unverifiedOrganisations',
                label: 'Unverified organisations',
            },
            {
                key: 'numberOfCountries',
                label: 'Number of Countries',
            },
            {
                key: 'activeUsers',
                label: 'Active users',
            },
        ];

        const metricValues = ref({
            activeOrganisations: 0,
            unverifiedOrganisations: 0,
            numberOfCountries: 0,
            activeUsers: 0,
        });

        exec(AdminAPI.getStatistics())
            .then((res: any) => (metricValues.value = res.data))
            .catch((e: any) => root.$toastr.e(e.response.data.message));

        return {
            loading,
            metricLabels,
            metricValues,
        };
    },
});
