import { useAxios } from '@/app/composable';
import { computed, ref, Ref } from '@vue/composition-api';
import { InternalSchedulesAPI } from '../api';
import { InternalScheduleFrequency } from '../constants';
import { InternalSchedule, InternalScheduleUpdate } from '../types';

export function useInternalSchedules(searchText: Ref<string>, showEnabled: Ref<boolean | null>, fetchOnInit = true) {
    const { exec, loading, error } = useAxios(true);
    const allSchedules: Ref<InternalSchedule[]> = ref<InternalSchedule[]>([]);

    const total: Ref<number> = computed(() => allSchedules.value.length);

    const schedules: Ref<InternalSchedule[]> = computed(() => {
        return allSchedules.value.filter((schedule: InternalSchedule) => {
            if (showEnabled.value === true && !schedule.isEnabled) return false;
            if (showEnabled.value === false && schedule.isEnabled) return false;
            return (
                schedule.event.toLowerCase().includes(searchText.value.toLowerCase()) ||
                (schedule.description && schedule.description.toLowerCase().includes(searchText.value.toLowerCase()))
            );
        });
    });

    const refetch = () => {
        exec(InternalSchedulesAPI.all()).then((res: any) => {
            allSchedules.value = res?.data;
        });
    };

    const run = (scheduleId: string) => {
        exec(InternalSchedulesAPI.run(scheduleId));
    };

    const update = (scheduleId: string, data: InternalScheduleUpdate) => {
        exec(InternalSchedulesAPI.update(scheduleId, data)).then(() => refetch());
    };

    const enable = (scheduleId: string) => {
        exec(InternalSchedulesAPI.enable(scheduleId)).then(() => refetch());
    };

    const disable = (scheduleId: string) => {
        exec(InternalSchedulesAPI.disable(scheduleId)).then(() => refetch());
    };
    if (fetchOnInit) refetch();

    return { schedules, loading, error, total, run, update, refetch, enable, disable };
}
