











































































import { DateTimePicker, Scrollbar } from '@/app/components';
import { useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { ExecutionStatus, ExecutionType } from '@/modules/workflow-designer/constants';
import { computed, defineComponent, ref, watch, watchEffect } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { clone, concat } from 'ramda';
import { ExecutionsFiltersFacet, ExecutionsFiltersQuery } from '../../types';

dayjs.extend(utc);

export default defineComponent({
    name: 'ExecutionsFilters',
    components: {
        DateTimePicker,
        Scrollbar,
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const router = useRouter();
        const { get, set } = useQueryParams(root, router, 'admin-portal');

        const searchRef = ref<any>();

        const searchText = computed({
            get: (): string => filters.value.search,
            set: (value: string) => {
                set('search', value, '');
                filters.value.search = value;
            },
        });

        const facets = ref<Record<string, ExecutionsFiltersFacet>>({
            type: {
                label: 'Type',
                values: [ExecutionType.Dry, ExecutionType.Normal, ExecutionType.Test, ExecutionType.LongRunning],
                default: [ExecutionType.Normal],
            },
            status: {
                label: 'Status',
                values: [
                    ExecutionStatus.Queued,
                    ExecutionStatus.Running,
                    ExecutionStatus.Cancelled,
                    ExecutionStatus.Failed,
                    ExecutionStatus.Completed,
                ],
            },
        });

        const timeTravel = ref<Record<string, { default?: string | null }>>({
            fromDate: {
                default: dayjs.utc().subtract(7, 'day').toISOString(),
            },
            toDate: {
                default: null,
            },
        });

        const filters = ref<ExecutionsFiltersQuery>({
            search: get('search', false, ''),
            facets: {
                ...Object.keys(facets.value).reduce((obj: any, key: string) => {
                    const defaults = facets.value[key].default || [];
                    let values = get(key, true, []);

                    if (!values.length) {
                        values = concat(values, defaults);
                    }

                    values = Array.from(new Set(values));
                    obj[key] = values;
                    set(key, values.join(','));
                    return obj;
                }, {}),
            },
            timeTravel: {
                ...Object.keys(timeTravel.value).reduce((obj: any, key: string) => {
                    const defaultValue = timeTravel.value[key].default;
                    let value = get(key, false, null);

                    obj[key] = value ? value : defaultValue;
                    set(key, value);
                    return obj;
                }, {}),
            },
        });

        const handleFacetChange = (value: string, key: string) => {
            const obj = filters.value.facets;
            const values = new Set([...obj[key]]);

            if (values.has(value)) {
                values.delete(value);
            } else {
                values.add(value);
            }

            obj[key] = Array.from(values);
            set(key, obj[key].join(','));
        };

        const fromDate = computed({
            get: (): string => filters.value.timeTravel.fromDate,
            set: (value: string) => {
                set('fromDate', value, null);
                filters.value.timeTravel.fromDate = value;
            },
        });

        const toDate = computed({
            get: (): string => filters.value.timeTravel.toDate,
            set: (value: string) => {
                set('toDate', value, null);
                filters.value.timeTravel.toDate = value;
            },
        });

        const handleFromDateChange = (value: string) => (fromDate.value = value);
        const handleToDateChange = (value: string) => (toDate.value = value);

        watchEffect(() => {
            if (searchRef.value) {
                searchRef.value.focus();
            }
        });

        watch(
            () => filters.value,
            (newFilters: ExecutionsFiltersQuery) => emit('change', clone(newFilters)),
            { immediate: true, deep: true },
        );

        return {
            searchRef,
            searchText,
            facets,
            filters,
            fromDate,
            toDate,
            handleFacetChange,
            handleFromDateChange,
            handleToDateChange,
        };
    },
});
