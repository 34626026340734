



























































import { ExecutionErrorsAPI } from '@/app/api';
import { ConfirmButton, TailwindTable } from '@/app/components';
import { ColumnPosition, ColumnType } from '@/app/constants';
import store from '@/app/store';
import { ExecutionError, ExecutionErrorCategory } from '@/app/store/execution-errors';
import { useAxios } from '@/app/composable';
import { ExclamationCircleIcon, PencilAltIcon, PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import ExecutionErrorModal from './ExecutionErrorModal.vue';

export default defineComponent({
    name: 'ExecutionErrors',
    components: {
        TailwindTable,
        PencilAltIcon,
        TrashIcon,
        ExclamationCircleIcon,
        PlusIcon,
        ConfirmButton,
        ExecutionErrorModal,
    },
    setup() {
        const { exec, error } = useAxios(true);

        const mode = ref<string>('Create');
        const showEditExecutionErrorModal = ref<boolean>(false);
        const executionErrorToUpdate = ref<ExecutionError>({
            code: '',
            category: ExecutionErrorCategory.Generic,
            title: '',
            description: '',
        });

        const categories = {
            [ExecutionErrorCategory.Generic]: 'bg-neutral-400 text-neutral-800',
            [ExecutionErrorCategory.Harvester]: 'bg-blue-100 text-blue-800',
            [ExecutionErrorCategory.Mapping]: 'bg-orange-100 text-orange-900',
            [ExecutionErrorCategory.Cleaning]: 'bg-green-100 text-green-800',
            [ExecutionErrorCategory.Anonymiser]: 'bg-red-100 text-red-800',
            [ExecutionErrorCategory.Encryptor]: 'bg-cherry-100 text-cherry-600',
            [ExecutionErrorCategory.Loader]: 'bg-olive-100 text-olive-600',
        };

        const columns = computed(() => [
            {
                key: 'index',
                label: '#',
                type: ColumnType.Integer,
            },
            {
                key: 'code',
                label: 'Code',
                type: ColumnType.String,
            },
            {
                key: 'category',
                label: 'Category',
                type: ColumnType.String,
            },
            {
                key: 'title',
                label: 'Title',
                type: ColumnType.String,
            },
            {
                key: 'description',
                label: 'Description',
                type: ColumnType.String,
            },
            {
                key: 'actions',
                label: 'Actions',
                position: ColumnPosition.Right,
            },
        ]);

        const executionErrors = computed(() => store.state.executionErrors.executionErrors);

        const rows = computed(() =>
            executionErrors.value.map((err: any, index: number) => {
                return { ...err, index: index + 1 };
            }),
        );

        const closeEditExecutionErrorModal = () => {
            error.value = null;
            showEditExecutionErrorModal.value = false;
        };

        const updateExecutionErrorClicked = (executionError: ExecutionError) => {
            executionErrorToUpdate.value = executionError;
            mode.value = 'Edit';
            showEditExecutionErrorModal.value = true;
        };

        const addExecutionError = () => {
            executionErrorToUpdate.value = {
                code: '',
                category: ExecutionErrorCategory.Generic,
                title: '',
                description: '',
            };
            mode.value = 'Create';
            showEditExecutionErrorModal.value = true;
        };

        const createExecutionError = (executionError: ExecutionError) => {
            exec(ExecutionErrorsAPI.createExecutionError(executionError)).then(() => {
                store.dispatch.executionErrors.loadExecutionErrors();
                closeEditExecutionErrorModal();
            });
        };

        const updateExecutionError = (executionError: ExecutionError) => {
            exec(ExecutionErrorsAPI.updateExecutionError(executionError.code, executionError)).then(() => {
                store.dispatch.executionErrors.loadExecutionErrors();
                closeEditExecutionErrorModal();
            });
        };

        const deleteExecutionError = (code: string) => {
            exec(ExecutionErrorsAPI.deleteExecutionError(code)).then(() =>
                store.dispatch.executionErrors.loadExecutionErrors(),
            );
        };

        return {
            categories,
            columns,
            rows,
            executionErrorToUpdate,
            showEditExecutionErrorModal,
            error,
            mode,
            addExecutionError,
            createExecutionError,
            updateExecutionErrorClicked,
            updateExecutionError,
            deleteExecutionError,
            closeEditExecutionErrorModal,
        };
    },
});
