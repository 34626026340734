

























































import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { AlertBanner, ButtonGroup, Scrollbar, SearchBox } from '@/app/components';
import { useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { computed, defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { useInternalSchedules } from '../../composables';
import { InternalScheduleFrequency } from '../../constants';
import InternalSchedule from './InternalSchedule.vue';
export default defineComponent({
    name: 'InternalSchedules',
    components: { SearchBox, ButtonGroup, InternalSchedule, AlertBanner, Scrollbar, SelfBuildingSquareSpinner },
    setup(props, { root }) {
        const enabledFilters = [
            { label: 'All', tooltip: 'All schedules, both enabled and disabled' },
            { label: 'Enabled', tooltip: 'Only enabled schedules' },
            { label: 'Disabled', tooltip: 'Only disabled schedules' },
        ];
        const router = useRouter();

        const { get: getQueryParam, set: setQueryParam } = useQueryParams(root, router, 'admin-portal');

        const searchText: Ref<string> = ref<string>(getQueryParam('searchText', false, ''));
        const enabledFilter: Ref<string> = ref<string>(getQueryParam('enabled', false, 'All'));

        const showEnabled: Ref<boolean | null> = computed(() => {
            switch (enabledFilter.value) {
                case 'Enabled':
                    return true;
                case 'Disabled':
                    return false;
                default:
                    return null;
            }
        });
        const isAdmin: Ref<boolean> = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const { schedules, total, loading, error, run, update, enable, disable } = useInternalSchedules(
            searchText,
            showEnabled,
        );

        const frequencies = computed(() =>
            Object.keys(InternalScheduleFrequency).map((key: string) => {
                return {
                    label: key,
                    key: InternalScheduleFrequency[key],
                };
            }),
        );

        watch(
            () => searchText.value,
            (newSearchText: string) => setQueryParam('searchText', newSearchText, ''),
        );

        watch(
            () => enabledFilter.value,
            (newEnabledFilter: string) => setQueryParam('enabled', newEnabledFilter, 'All'),
        );

        return {
            error,
            total,
            loading,
            isAdmin,
            schedules,
            searchText,
            frequencies,
            enabledFilter,
            enabledFilters,
            run,
            update,
            enable,
            disable,
        };
    },
});
