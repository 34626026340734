var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormModal',{attrs:{"title":(_vm.mode + " Execution Error")},on:{"cancel":function($event){return _vm.$emit('close')},"save":_vm.validateAndProceed}},[_c('validation-observer',{ref:"content"},[(_vm.error && _vm.error.response && _vm.error.response.data && _vm.error.response.data.message)?_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(" "+_vm._s(_vm.error.response.data.message)+" ")]):_vm._e(),_c('div',{staticClass:"flex space-x-4"},[_c('label',{staticClass:"block w-1/2 p-1"},[_c('validation-provider',{attrs:{"rules":"required|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Code")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updatedExecutionError.code),expression:"updatedExecutionError.code",modifiers:{"trim":true}}],staticClass:"block w-full mt-1 text-sm form-input",class:{ 'border-red-600': errors.length },attrs:{"name":"Code","type":"text","placeholder":"Enter code","disabled":_vm.mode === 'Edit'},domProps:{"value":(_vm.updatedExecutionError.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updatedExecutionError, "code", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('label',{staticClass:"block w-1/2 p-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Category")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatedExecutionError.category),expression:"updatedExecutionError.category"}],staticClass:"block w-full mt-1 text-sm",attrs:{"name":"Category"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.updatedExecutionError, "category", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories),function(category){return _c('option',{key:category,domProps:{"value":category}},[_vm._v(" "+_vm._s(category)+" ")])}),0),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"flex space-x-4"},[_c('label',{staticClass:"block w-full p-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updatedExecutionError.title),expression:"updatedExecutionError.title",modifiers:{"trim":true}}],staticClass:"block w-full mt-1 text-sm form-input",class:{ 'border-red-600': errors.length },attrs:{"name":"Title","type":"text","placeholder":"Enter title"},domProps:{"value":(_vm.updatedExecutionError.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updatedExecutionError, "title", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"flex space-x-4"},[_c('label',{staticClass:"block w-full p-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updatedExecutionError.description),expression:"updatedExecutionError.description",modifiers:{"trim":true}}],staticClass:"block w-full mt-1 text-sm form-input",class:{ 'border-red-600': errors.length },attrs:{"name":"Description","type":"text","placeholder":"Enter description"},domProps:{"value":(_vm.updatedExecutionError.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updatedExecutionError, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }