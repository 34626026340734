













































































import { FormModal } from '@/app/components';
import { ExecutionError, ExecutionErrorCategory } from '@/app/store/execution-errors';
import { PropType, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { length } from 'vee-validate/dist/rules';

extend('length', { ...length, message: 'Code must be equal to 4 characters.' });

export default defineComponent({
    name: 'ExecutionErrorModal',
    components: {
        FormModal,
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        executionError: {
            type: Object as PropType<ExecutionError>,
            required: true,
        },
        mode: {
            type: String,
            default: 'create',
        },
        error: {
            type: Error,
            default: null,
        },
    },
    setup(props, { emit }) {
        const content = ref<any>(null);
        const categories = ref<ExecutionErrorCategory[]>(Object.values(ExecutionErrorCategory));

        const updatedExecutionError = ref<ExecutionError>(props.executionError);

        const validateAndProceed = async () => {
            const valid = await content.value.validate();
            if (valid)
                emit(
                    props.mode === 'Edit' ? 'update-execution-error' : 'create-execution-error',
                    updatedExecutionError.value,
                );
        };

        return { content, updatedExecutionError, categories, validateAndProceed };
    },
});
