




































import { TailwindTable } from '@/app/components';
import { ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { useAxios } from '@/app/composable';
import { RefreshIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import BlockAPI from '../../../workflow-designer/api/block';
export default defineComponent({
    name: 'Blocks',
    components: {
        RefreshIcon,
        TailwindTable,
        OrbitSpinner,
    },
    setup(_, { root, emit }) {
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const { exec, loading } = useAxios(true);

        const columns = [
            {
                key: 'index',
                label: '#',
                type: ColumnType.Number,
            },
            {
                key: 'id',
                label: 'Id',
                type: ColumnType.String,
            },
            {
                key: 'version',
                label: 'Version',
                type: ColumnType.String,
            },
            {
                key: 'executionImageType',
                label: 'Pipeline Type',
                type: ColumnType.String,
            },
            {
                key: 'frameworkVersion',
                label: 'Framework Version',
                type: ColumnType.String,
            },
            {
                key: 'updatedAt',
                label: 'Updated At',
                type: ColumnType.Datetime,
                formatInUtc: true,
            },
            {
                key: 'deletedAt',
                label: 'Disabled At',
                type: ColumnType.Datetime,
                formatInUtc: true,
            },
        ];

        const blocks = ref<any[]>([]);

        const getBlocks = async () => {
            await exec(BlockAPI.getAllBlocks())
                .then((res: any) => {
                    blocks.value = res?.data.map((block: any, index: number) => ({
                        ...block,
                        frameworkVersion: `${block.executionImageVersionFrom}${
                            block.executionImageVersionTo ? ' - ' + block.executionImageVersionTo : '+'
                        }`,
                        index: index + 1,
                    }));
                })
                .catch(() => {
                    (root as any).$toastr.e('Error retrieving blocks', 'Error');
                });
        };

        const syncBlocks = async () => {
            await exec(BlockAPI.syncBlocks())
                .then(() => {
                    getBlocks();
                    store.dispatch.executionVersions.loadVersions();
                })
                .catch(() => {
                    (root as any).$toastr.e('Error syncing blocks from block repository', 'Error');
                });
        };

        const dataCheckinExecutionVersion = computed(() => store.state.executionVersions.dataCheckinExecutionVersion);
        const analyticsExecutionVersion = computed(() => store.state.executionVersions.analyticsExecutionVersion);

        getBlocks();

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            loading,
            isAdmin,
            blocks,
            getBlocks,
            columns,
            syncBlocks,
            dataCheckinExecutionVersion,
            analyticsExecutionVersion,
        };
    },
});
