var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.query)?_c('div',{staticClass:"flex flex-col overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":_vm.changePage,"sort":_vm.handleChangeSort},scopedSlots:_vm._u([{key:"businessName",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('div',[_c('span',[_vm._v(_vm._s(value))]),(row.createdBy)?_c('div',{staticClass:"text-xs italic space-x-05 text-neutral-500"},[_c('span',[_vm._v(_vm._s(row.createdBy.firstName)+" "+_vm._s(row.createdBy.lastName))])]):_vm._e()])]}},{key:"country",fn:function(ref){
var row = ref.row;
return [(row.location)?_c('span',[_vm._v(_vm._s(row.location.country.name))]):_vm._e()]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"inline-flex px-2 text-xs font-semibold leading-5 capitalize py-0.5 rounded-full",class:{
                    'bg-green-200 text-green-800': row.status === _vm.UserStatus.Active,
                    'text-yellow-800 bg-yellow-200': row.status === _vm.UserStatus.Pending,
                    'text-neutral-800 bg-neutral-200': row.status === _vm.UserStatus.Suspended,
                    'text-red-800 bg-red-100': row.status === _vm.UserStatus.Deleted,
                }},[_vm._v(" "+_vm._s(row.status)+" ")])]}},{key:"users",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.__users__.length))])]}},{key:"actions",fn:function(ref){
                var row = ref.row;
return [(row.id !== _vm.user.organisationId)?_c('div',{staticClass:"flex space-x-2 text-neutral-500"},[(row.status === 'pending')?_c('ShieldCheckIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Verify"),expression:"`Verify`"}],staticClass:"w-5 outline-none cursor-pointer hover:text-neutral-700",on:{"click":function($event){return _vm.verifyOrganisation(row.id)}}}):_vm._e(),(row.status === 'active')?_c('BanIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Suspend"),expression:"`Suspend`"}],staticClass:"w-5 outline-none cursor-pointer hover:text-neutral-700",on:{"click":function($event){return _vm.handleOrganisationStatusUpdate(row.id, row.status === _vm.UserStatus.Active)}}}):_vm._e(),(row.status === 'suspended')?_c('CheckCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Activate"),expression:"`Activate`"}],staticClass:"w-5 outline-none cursor-pointer hover:text-neutral-700",on:{"click":function($event){return _vm.handleOrganisationStatusUpdate(row.id, row.status === _vm.UserStatus.Active)}}}):_vm._e(),_c('TrashIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Delete"),expression:"`Delete`"}],staticClass:"w-5 outline-none cursor-pointer hover:text-neutral-700",on:{"click":function($event){return _vm.deleteOrganisationConfirm(row.id)}}})],1):_vm._e()]}}],null,false,2387331201)}),_c('portal',{attrs:{"to":"modals"}},[(_vm.showDeleteOrganisation)?_c('ConfirmModal',{attrs:{"title":"Delete Organisation","message":"Are you sure that you want to delete this organisation? By deleting the organisation you also delete every corresponding data. THIS ACTION IS NOT REVERSIBLE","okButtonText":"Confirm","color":"red"},on:{"cancel":function($event){_vm.showDeleteOrganisation = false},"ok":_vm.deleteOrganisation}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }