
































































































import { AlertBanner, TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { DocumentTextIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { clone } from 'ramda';
import { AdminAPI } from '../../api';
import { ExecutionsFiltersTimeTravel, ExecutionsQuery } from '../../types';
import ExecutionsFilters from './ExecutionsFilters.vue';

export default defineComponent({
    name: 'Executions',
    components: {
        TailwindTable,
        DocumentTextIcon,
        SelfBuildingSquareSpinner,
        AlertBanner,
        ExecutionsFilters,
    },
    props: {
        query: {
            type: Object as PropType<ExecutionsQuery>,
        },
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const { exec, loading } = useAxios(true);
        const errorMessage = ref<string | null>(null);

        const columns = computed(() => [
            {
                key: 'id',
                label: 'ID',
                type: ColumnType.String,
                copy: true,
            },
            {
                key: 'type',
                label: 'Type',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'createdAt',
                label: 'Created At',
                type: ColumnType.Datetime,
                sortable: true,
                formatInUtc: true,
            },
            {
                key: 'updatedAt',
                label: 'Updated At',
                type: ColumnType.Datetime,
                sortable: true,
                formatInUtc: true,
            },
            {
                key: 'status',
                label: 'Status',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Actions',
                position: ColumnPosition.Right,
            },
        ]);

        const filters = ref<{
            search: string;
            facets: any;
            timeTravel: ExecutionsFiltersTimeTravel;
        }>();

        const executionQuery = computed(() => {
            if (!filters.value) return undefined;

            return {
                ...props.query,
                search: filters.value.search,
                facets: {
                    ...filters.value.facets,
                },
                timeTravel: {
                    ...filters.value.timeTravel,
                },
            };
        });

        const executions: Ref<any> = ref<any>();
        const executionsCount: Ref<number> = ref<number>(0);
        const executionConfigRef: Ref<any> = ref();

        const rows = computed(() => executions?.value);
        const { pagination, handleChangePage } = usePagination(root, {
            total: computed(() => executionsCount.value),
        });

        const fetchExecutionsById = async () => {
            if (!executionQuery.value?.search) return;

            exec(AdminAPI.getExecutionsById(executionQuery.value, pagination.value))
                .then((res: any) => {
                    executions.value = res.data.data;
                    executionsCount.value = res.data.meta.totalItems;
                    errorMessage.value = null;
                })
                .catch((e) => {
                    if (e.response.status == 404) {
                        errorMessage.value = `Executions could not be retrieved. ${
                            Number.isInteger(Number(executionQuery.value?.search))
                                ? 'Data Check-in Pipeline'
                                : 'Analytics Pipeline'
                        } ID not found!`;
                    } else {
                        errorMessage.value = e.response.data.message;
                    }
                    clearExecutionsData();
                });
        };

        const fetchExecutionConfiguration = async (executionId: string) => {
            exec(AdminAPI.getExecutionConfiguration(executionId))
                .then((res: any) => {
                    if (res.data) {
                        executionConfigRef.value.value = JSON.stringify(res.data);
                        executionConfigRef.value.setAttribute('type', 'text');
                        executionConfigRef.value.select();
                        document.execCommand('copy');
                        executionConfigRef.value.setAttribute('type', 'hidden');
                    }
                })
                .catch(() => root.$toastr.e('Execution configuration does not exist.', 'Error'));
        };

        const handleFiltersChange = (newFilters: any) => {
            if (filters.value) {
                handleChangePage(1);
            }
            filters.value = clone(newFilters);
        };

        const clearExecutionsData = () => {
            executions.value = [];
            executionsCount.value = 0;
        };

        watch(
            () => executionQuery.value,
            () => {
                if (executionQuery.value?.search) {
                    fetchExecutionsById();
                } else {
                    clearExecutionsData();
                }
            },
            { deep: true, immediate: true },
        );

        const changePage = (newPage: number) => {
            handleChangePage(newPage);
            fetchExecutionsById();
        };

        const handleChangeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        return {
            filters,
            columns,
            rows,
            loading,
            pagination,
            changePage,
            errorMessage,
            executionQuery,
            executionConfigRef,
            handleChangeSort,
            handleFiltersChange,
            fetchExecutionConfiguration,
        };
    },
});
