import { AxiosRequestConfig } from 'axios';

const endpoint = 'api/users';

export default {
    activateMember: (id: number) => ({ method: 'PATCH', url: `${endpoint}/${id}/enable` } as AxiosRequestConfig),
    promote: (id: number) => ({ method: 'PATCH', url: `${endpoint}/${id}/promote` } as AxiosRequestConfig),
    demote: (id: number) => ({ method: 'PATCH', url: `${endpoint}/${id}/demote` } as AxiosRequestConfig),
    deactivateMemberDry: (id: number) => ({ method: 'DELETE', url: `api/admin/users/${id}/dry` } as AxiosRequestConfig),
    deactivateMember: (id: number) => ({ method: 'DELETE', url: `api/admin/users/${id}` } as AxiosRequestConfig),
    disableMember: (id: number) => ({ method: 'PATCH', url: `${endpoint}/${id}/disable` } as AxiosRequestConfig),
    resendInvitation: (id: number) =>
        ({ method: 'POST', url: `${endpoint}/${id}/resend-invitation` } as AxiosRequestConfig),
    cancelInvitation: (id: number) =>
        ({ method: 'POST', url: `${endpoint}/${id}/cancel-invitation` } as AxiosRequestConfig),
};
