







































































































































import { TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnPosition, ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { Asset } from '@/modules/asset/types';
import { UserStatus } from '@/modules/organization/constants/user.constants';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { Workflow } from '@/modules/workflow-designer/types';
import { BanIcon, CheckCircleIcon, MailOpenIcon, TrashIcon, XCircleIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { clone } from 'ramda';
import { UsersAPI } from '../../../organization/api';
import DerivedAssetsModal from '../../../organization/components/DerivedAssetsModal.vue';
import { AdminAPI } from '../../api';

export default defineComponent({
    name: 'Users',
    components: {
        TailwindTable,
        BanIcon,
        CheckCircleIcon,
        TrashIcon,
        DerivedAssetsModal,
        MailOpenIcon,
        XCircleIcon,
    },
    props: {
        query: {
            type: Object as PropType<{ sortBy: { field: string; asc: boolean } }>,
        },
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const { exec, loading } = useAxios(true);
        const user = computed(() => store.state.auth.user);
        const showInviteUserModal = ref<boolean>(false);
        const derivedAssets = ref<Asset[]>([]);
        const derivedWorkflows = ref<Workflow[]>([]);
        const derivedRetrievalQueries = ref<RetrievalQuery[]>([]);
        const deletedUserId = ref<number | null>();
        const showConfirmDeleteModal = ref(false);

        const rolesMapping = {
            [UserRoles.Admin]: 'bg-red-100 text-red-800',
            [UserRoles.Manager]: 'bg-green-100 text-green-800',
            [UserRoles.ModelModerator]: 'bg-blue-100 text-blue-800',
            [UserRoles.Member]: 'bg-neutral-200 text-neutral-800',
            [UserRoles.Guest]: 'bg-orange-100 text-orange-800',
        };

        const columns = computed(() => [
            {
                key: 'name',
                label: 'Name',
                type: ColumnType.String,
                sortable: true,
            },
            {
                key: 'openId',
                label: 'Open ID',
                type: ColumnType.String,
            },
            {
                key: 'userId',
                label: 'ID',
                type: ColumnType.Integer,
            },
            {
                key: 'organisationName',
                label: 'Organisation',
                type: ColumnType.String,
                sortable: true,
            },
            {
                key: 'organisationRoles',
                label: 'Organisation Roles',
                type: ColumnType.Array,
                position: ColumnPosition.Center,
            },
            {
                key: 'status',
                label: 'Status',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Actions',
                position: ColumnPosition.Right,
            },
        ]);

        const users: Ref<any> = ref<any>([]);
        const usersCount = ref<number>(0);
        const { pagination, handleChangePage } = usePagination(root, { total: computed(() => usersCount.value) });

        const sortedUsers = computed(() => {
            if (props.query?.sortBy) {
                const field = props.query.sortBy.field === 'name' ? 'firstName' : props.query.sortBy.field;
                return R.sort(R[`${props.query.sortBy.asc ? 'ascend' : 'descend'}`](R.prop(field) as any), users.value);
            }
            return users.value;
        });

        const paginatedUsers = computed(() =>
            sortedUsers.value.slice(
                (pagination.value.page - 1) * pagination.value.pageSize,
                pagination.value.page * pagination.value.pageSize,
            ),
        );

        const fetchUsers = async () => {
            await exec(AdminAPI.getUsers())
                .then((res: any) => {
                    if (res?.data) {
                        users.value = res.data;
                        usersCount.value = res.data.length;
                    }
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const filteredRoles = (roles: string[]) => {
            return roles.filter((role: string) => role !== UserRoles.User);
        };

        const sortedRoles = (value: string[]) => {
            const clonedValue = clone(value);
            const map = Object.fromEntries(Object.keys(rolesMapping).map((k: string, idx: number) => [k, idx]));

            clonedValue.sort((a, b) => map[a] - map[b]);
            return clonedValue;
        };

        const handleChangeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const changePage = (newPage: number) => {
            handleChangePage(newPage);
            fetchUsers();
        };

        const activateUser = (id: number) => {
            exec(UsersAPI.activateMember(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been activated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be activated due to an error.', 'Error');
                });
        };

        const suspendUser = (id: number) => {
            exec(UsersAPI.disableMember(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const deactivateUserDry = (id: number) => {
            exec(UsersAPI.deactivateMemberDry(id))
                .then((response) => {
                    derivedAssets.value = response?.data.derivativeAssets;
                    derivedWorkflows.value = response?.data.derivativeWorkflows;
                    derivedRetrievalQueries.value = response?.data.derivativeRetrievalQueries;
                    deletedUserId.value = id;
                    showConfirmDeleteModal.value = true;
                })
                .catch(() => {
                    deletedUserId.value = null;
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const deactivateUser = () => {
            if (!deletedUserId.value) return;
            exec(UsersAPI.deactivateMember(deletedUserId.value))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                })
                .finally(() => {
                    deletedUserId.value = null;
                    showConfirmDeleteModal.value = false;
                });
        };

        const resendInvitation = (id: number) => {
            exec(UsersAPI.resendInvitation(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Invitation has been resent', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to resend invitation.', 'Error');
                });
        };

        const cancelInvitation = (id: number) => {
            exec(UsersAPI.cancelInvitation(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Invitation has been cancelled', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to cancel invitation.', 'Error');
                });
        };

        const cancelDelete = () => {
            derivedAssets.value = [];
            derivedWorkflows.value = [];
            derivedRetrievalQueries.value = [];
            showConfirmDeleteModal.value = false;
        };

        watch(
            () => props.query,
            (query) => {
                if (query) {
                    fetchUsers();
                }
            },
            { immediate: true },
        );

        return {
            UserStatus,
            columns,
            paginatedUsers,
            loading,
            pagination,
            user,
            rolesMapping,
            sortedRoles,
            handleChangeSort,
            changePage,
            activateUser,
            suspendUser,
            deactivateUser,
            deactivateUserDry,
            showConfirmDeleteModal,
            cancelDelete,
            derivedAssets,
            derivedWorkflows,
            derivedRetrievalQueries,
            resendInvitation,
            cancelInvitation,
            showInviteUserModal,
            filteredRoles,
        };
    },
});
