





































































































































































































































































































































































































































































































































































































import { ConfirmModal, FormBlock, Scrollbar, SvgImage, Tabs, TwButton } from '@/app/components';
import { useAxios, useFeatureFlags, useQuery, useResult } from '@/app/composable';
import { tokenScopes } from '@/app/constants/scopes';
import store from '@/app/store';
import { RunnerAPI } from '@/modules/apollo/api';
import { useKeycloak } from '@/modules/auth/composables/keycloak';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { confirmed, max, min, required } from 'vee-validate/dist/rules';
import { AccessToken, AccessTokenScopes } from '.';
import { AccessTokenAPI, AuthAPI } from '../api';
import GET_USER_DEPARTMENTS from '../graphql/getUserDepartments.graphql';
import { AccessTokenScope } from '../types/access-token-scope.interface';
import RunnerTokens from './RunnerTokens.vue';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('min', {
    ...min,
    message: '{_field_} must be longer than or equal to 8 characters.',
});
extend('max', {
    ...max,
    message: '{_field_} must be less than or equal to 24 characters.',
});
extend('confirmed', { ...confirmed, message: 'Repeat Password does not match.' });

export default defineComponent({
    name: 'EditUser',
    metaInfo: {
        title: 'Edit User Details',
    },
    components: {
        FormBlock,
        ValidationProvider,
        ValidationObserver,
        OrbitSpinner,
        TwButton,
        ConfirmModal,
        Tabs,
        AccessTokenScopes,
        AccessToken,
        RunnerTokens,
        SvgImage,
        Scrollbar,
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const { flag, areAnyEnabled } = useFeatureFlags();
        const isOnPremiseRunnerEnabled = flag('on-premise');
        const user = ref(store.state.auth.user);
        const tokenToCopy = ref<any>(null);
        const hostToCopy = ref<any>(null);
        const removeToken = ref(false);
        const showDeleteTokenModal = ref(false);
        const showDeleteRunnerModal = ref(false);
        const tokenToBeDeleted = ref(null);
        const runnerToBeDeleted = ref(null);
        const { isEnabled } = useKeycloak();
        const isKeycloakEnabled = isEnabled();
        const availableScopes = computed(() => tokenScopes.filter((tokenScope) => areAnyEnabled(tokenScope.features)));
        const isAccessTokensAvailable = computed(() => availableScopes.value.length > 0);

        const tabs = computed(() => {
            const availableTabs = [{ id: 0, title: 'User Profile' }];
            if (isAccessTokensAvailable.value) availableTabs.push({ id: 1, title: 'Access Tokens' });
            if (isOnPremiseRunnerEnabled.value) availableTabs.push({ id: 2, title: 'On-Premise Runner' });
            return availableTabs;
        });
        const activeTab = ref(0);

        const createToken = ref(false);
        const generatedToken = ref(false);
        const tokenGeneration: any = reactive({
            name: null,
            token: '',
            scopes: [],
        });
        const accessTokens: any = ref([]);

        const registerRunner = ref(false);
        const registeredRunner = ref(false);
        const runnerRegistration: any = reactive({
            name: null,
            token: '',
        });
        const runners: any = ref([]);

        const totalScopes = ref(availableScopes.value.map((scope) => ({ ...scope, checked: false })));

        const retrieveTokens = async () => {
            accessTokens.value = [];
            try {
                const res = await exec(AccessTokenAPI.retrieveTokens());
                accessTokens.value = res?.data;
            } catch (e: any) {
                (root as any).$toastr.e('An error occurred.', 'Error');
            }
        };

        if (isOnPremiseRunnerEnabled.value)
            exec(RunnerAPI.all())
                .then((res: any) => {
                    for (let i = 0; i <= res.data.length; i += 1) {
                        if (res.data[i]) {
                            const runner = {
                                name: res.data[i].name,
                                id: res.data[i].id,
                                framworks: res.data[i].frameworks,
                            };

                            runners.value.push(runner);
                        }
                    }
                })
                .catch(() => {
                    (root as any).$toastr.e('An error occurred.', 'Error');
                });

        const userRef = ref<any>(null);
        const userUpdateDetails = reactive({
            firstName: user.value.firstName,
            lastName: user.value.lastName,
        });
        const passwordChange = reactive({ password: null, newPassword: null, repeatPassword: null });
        const passwordRef = ref<any>(null);
        const { loading: departmentsLoading, error: departmentsError, result } = useQuery(
            GET_USER_DEPARTMENTS,
            { id: user.value.id },
            { fetchPolicy: 'no-cache' },
        );
        const departments = useResult(result, null, (data: any) => data.userDepartments);

        // Methods

        const changePassword = async () => {
            const valid = await passwordRef.value.validate();
            if (valid) {
                try {
                    const { exec: execUpdate } = useAxios(true);
                    await execUpdate(AuthAPI.changePassword(passwordChange));

                    (root as any).$toastr.s('Password has been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Invalid password', 'Error');
                } finally {
                    passwordChange.password = null;
                    passwordChange.newPassword = null;
                    passwordChange.repeatPassword = null;

                    passwordRef.value.reset();
                }
            }
        };

        const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

        const saveChanges = async () => {
            const valid = userRef.value ? await userRef.value.validate() : true;

            if (valid) {
                user.value = { ...user.value, ...userUpdateDetails };
                try {
                    // if there is also change on user details update them too
                    if (userRef.value) {
                        exec(AuthAPI.updateUser(user.value));
                        store.commit.auth.SET_USER(user.value);
                    }

                    (root as any).$toastr.s('User data have been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Changing user data failed', 'Error');
                }
            }
        };

        const checkDifference = computed(() => {
            return (
                userUpdateDetails.firstName === user.value.firstName &&
                userUpdateDetails.lastName === user.value.lastName
            );
        });

        const cancel = async () => {
            root.$router.go(-1);
        };

        const showTokenGeneration = () => {
            createToken.value = true;
        };

        const showRunnerRegistration = () => {
            registerRunner.value = true;
        };

        const generateToken = () => {
            const scopes: Array<any> = totalScopes.value
                .filter((scope: AccessTokenScope) => scope.checked)
                .map((scope: AccessTokenScope) => scope.name);

            const payload = {
                name: tokenGeneration.name,
                scopes,
            };

            exec(AccessTokenAPI.generateToken(payload))
                .then(async (res: any) => {
                    tokenGeneration.token = res.data.key;
                    tokenGeneration.name = null;

                    createToken.value = false;
                    generatedToken.value = true;
                    await retrieveTokens();
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const generateRunnerToken = () => {
            const payload = {
                name: runnerRegistration.name,
            };

            exec(RunnerAPI.generateToken(payload))
                .then((res: any) => {
                    runnerRegistration.token = res.data.token;

                    registeredRunner.value = true;
                    registerRunner.value = false;
                    runnerRegistration.name = null;
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const cancelTokenGeneration = () => {
            createToken.value = false;
            tokenGeneration.name = null;
        };

        const cancelRunnerRegistration = () => {
            registerRunner.value = false;
            runnerRegistration.name = null;
        };

        const copyToClipboard = () => {
            tokenToCopy.value = document.querySelector('#token');
            if (tokenToCopy.value.value) {
                tokenToCopy.value.setAttribute('type', 'text');
                tokenToCopy.value.select();
                document.execCommand('copy');
                tokenToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const copyHostToClipboard = () => {
            hostToCopy.value = document.querySelector('#host');
            if (hostToCopy.value.value) {
                hostToCopy.value.setAttribute('type', 'text');
                hostToCopy.value.select();
                document.execCommand('copy');
                hostToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const deleteToken = (id: number) => {
            exec(AccessTokenAPI.deleteToken(id))
                .then(async () => await retrieveTokens())
                .catch(() => {
                    (root as any).$toastr.e('The token could not be deleted due to an error.', 'Error');
                })
                .finally(() => {
                    tokenToBeDeleted.value = null;
                    createToken.value = false;
                    showDeleteTokenModal.value = false;
                });
        };

        const deleteRunner = (id: string) => {
            exec(RunnerAPI.delete(id))
                .then(() => {
                    runners.value.splice(
                        runners.value.indexOf(runners.value.find((runner: any) => runner.id === id)),
                        1,
                    );
                })
                .catch(() => {
                    (root as any).$toastr.e('The runner could not be deleted due to an error.', 'Error');
                })
                .finally(() => {
                    (root as any).$toastr.s('The runner has been deleted successfully', 'Success');
                    runnerToBeDeleted.value = null;
                    showDeleteRunnerModal.value = false;
                });
        };

        const showScopes = (scopes: any) => {
            let finalScopes = '';
            for (let i = 0; i < scopes.length; i += 1) {
                if (i === scopes.length - 1) {
                    finalScopes += scopes[i];
                } else {
                    finalScopes += `${scopes[i]}, `;
                }
            }

            return finalScopes;
        };

        const checkScopes = computed(() => {
            for (let i = 0; i < totalScopes.value.length; i += 1) {
                if (totalScopes.value[i].checked) {
                    return false;
                }
            }
            return true;
        });

        const binaries = ref<any>({ tray: [], headless: [] });

        const getBinaries = () => {
            exec(RunnerAPI.getLatestBinaries()).then((res: any) => {
                binaries.value = res.data;
            });
        };

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        retrieveTokens();
        if (isOnPremiseRunnerEnabled.value) getBinaries();

        return {
            user,
            loading,
            error,
            departments,
            passwordChange,
            changePassword,
            saveChanges,
            userUpdateDetails,
            cancel,
            departmentsLoading,
            departmentsError,
            userRef,
            passwordRef,
            tabs,
            activeTab,
            generatedToken,
            showTokenGeneration,
            tokenGeneration,
            cancelTokenGeneration,
            generateToken,
            createToken,
            copyToClipboard,
            copyHostToClipboard,
            tokenToCopy,
            hostToCopy,
            deleteToken,
            removeToken,
            showDeleteTokenModal,
            showDeleteRunnerModal,
            accessTokens,
            tokenToBeDeleted,
            runnerToBeDeleted,
            showScopes,
            totalScopes,
            checkDifference,
            checkScopes,
            tabClicked,
            runners,
            cancelRunnerRegistration,
            registerRunner,
            registeredRunner,
            generateRunnerToken,
            showRunnerRegistration,
            runnerRegistration,
            BACKEND_URL,
            binaries,
            isKeycloakEnabled,
            deleteRunner,
            isOnPremiseRunnerEnabled,
            isAccessTokensAvailable,
        };
    },
});
