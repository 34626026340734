
















































































import { ConfirmModal, TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnPosition, ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { OrganizationsAPI } from '@/modules/organization/api';
import { UserStatus } from '@/modules/organization/constants/user.constants';
import { BanIcon, CheckCircleIcon, ShieldCheckIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import { AdminAPI } from '../../api';

export default defineComponent({
    name: 'Organisations',
    components: { TailwindTable, BanIcon, CheckCircleIcon, TrashIcon, ShieldCheckIcon, ConfirmModal },
    props: {
        query: {
            type: Object as PropType<{ sortBy: { field: string; asc: boolean } }>,
        },
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const { exec, loading } = useAxios(true);
        const user = computed(() => store.state.auth.user);
        const showDeleteOrganisation = ref<boolean>(false);
        const deletedOrgId = ref<number | null>();

        const columns = computed(() => [
            {
                key: 'id',
                label: 'ID',
                type: ColumnType.Integer,
            },
            {
                key: 'businessName',
                label: 'Business Name',
                type: ColumnType.String,
                sortable: true,
            },
            {
                key: 'country',
                label: 'Country',
                type: ColumnType.String,
            },
            {
                key: 'users',
                label: 'Users',
                type: ColumnType.Integer,
            },
            {
                key: 'status',
                label: 'Status',
                type: ColumnType.String,
                position: ColumnPosition.Center,
            },

            {
                key: 'actions',
                label: 'Actions',
                position: ColumnPosition.Right,
            },
        ]);

        const organisations: Ref<any> = ref<any>();
        const organisationsCount = ref<number>(0);
        const rows = computed(() => organisations?.value);

        const { pagination, handleChangePage } = usePagination(root, {
            total: computed(() => organisationsCount.value),
        });

        const fetchOrganisations = async () => {
            await exec(AdminAPI.getOrganisations(pagination.value, props.query?.sortBy))
                .then((res: any) => {
                    organisations.value = res.data.data;
                    pagination.value.pageSize = res.data.meta.itemsPerPage;
                    pagination.value.total = res.data.meta.totalItems;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const verifyOrganisation = async (id: number) => {
            await exec(OrganizationsAPI.verify(id))
                .then(() => {
                    root.$toastr.s('Organisation has been verified successfully', 'Success');
                    fetchOrganisations();
                })
                .catch((e) => root.$toastr.e(e.response.data.message, 'Error'));
        };

        const deleteOrganisationConfirm = async (id: number) => {
            deletedOrgId.value = id;
            showDeleteOrganisation.value = true;
        };

        const deleteOrganisation = async () => {
            if (!deletedOrgId.value) return;
            showDeleteOrganisation.value = false;
            await exec(AdminAPI.deleteOrganisation(deletedOrgId.value))
                .then(() => {
                    root.$toastr.s('Organisation has been verified successfully', 'Success');
                    fetchOrganisations();
                })
                .catch((e) => root.$toastr.e(e.response.data.message, 'Error'));
        };

        const handleOrganisationStatusUpdate = async (id: number, isEnabled: boolean) => {
            const endpoint = isEnabled ? AdminAPI.deactivateOrganisation(id) : AdminAPI.activateOrganisation(id);
            const message = `Organisation has been ${isEnabled ? 'deactivated' : 'activated'} successfuly`;

            await exec(endpoint)
                .then(() => {
                    root.$toastr.s(message, 'Success');
                    fetchOrganisations();
                })
                .catch((e) => root.$toastr.e(e.response.data.message, 'Error'));
        };

        const handleChangeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const changePage = (newPage: number) => {
            handleChangePage(newPage);
            fetchOrganisations();
        };

        watch(
            () => props.query,
            (query) => {
                if (query) {
                    fetchOrganisations();
                }
            },
            { immediate: true },
        );
        return {
            UserStatus,
            columns,
            rows,
            loading,
            pagination,
            user,
            UserRoles,
            showDeleteOrganisation,
            deletedOrgId,
            handleChangeSort,
            changePage,
            handleOrganisationStatusUpdate,
            verifyOrganisation,
            deleteOrganisation,
            deleteOrganisationConfirm,
        };
    },
});
